import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import CurrencyDefaults from '../currency-defaults';
import ReactSelect from 'react-select';
import ApiClient from '../api-client';
import UserSettings from '../user-settings';
import Template from './template';
import '../css/floating-labels.css';
import '../css/media-expenses.css';
import 'react-tooltip/dist/react-tooltip.css'
import ActualExpenseModal from '../components/actual-expense-modal'
import MoveExpenseModal from '../components/move-expense-modal'
import { jwtDecode } from "jwt-decode";
import {toast} from "react-toastify";
import CampaignGroupCell from "../components/media-expense-cell";
import Config from '../config';

const now = new Date();
const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
const nowYear = nowDate.getFullYear();
const monthsInYear = new Date(nowYear + 1, -1).getMonth();

function MediaExpenses(props) {
    
    const [companySelectValue, setCompanySelectValue] = useState('');
    const [companySelectData, setCompanySelectData] = useState([]);
    const [campaignSelectValue, setCampaignSelectValue] = useState('');
    const [campaignSelectData, setCampaignSelectData] = useState([]);
    const [platformSelectValue, setPlatformSelectValue] = useState([]);
    const [platformSelectData, setPlatformSelectData] = useState([]);
    const [invoiceNumberSearch, setInvoiceNumberSearch] = useState('');
    const [editingColumns, setEditingColumns] = useState(false);

    const [selectedYear, setSelectedYear] = useState(nowYear);
    const [years, setYears] = useState([]);
    const [visibleColumnIndexes, setVisibleColumnIndexes] = useState(UserSettings.getUserSettings().visibleColumnIndexes);
    const [showFlightDate, setShowFlightDate] = useState(UserSettings.getUserSettings().showFlightDate);
    const [showIo, setShowIo] = useState(UserSettings.getUserSettings().showIo);
    const [showIoAmount, setShowIoAmount] = useState(UserSettings.getUserSettings().showIoAmount);
    const [showPlannedAmount, setShowPlannedAmount] = useState(UserSettings.getUserSettings().showPlannedAmount);
    const [showMargin, setShowMargin] = useState(UserSettings.getUserSettings().showMargin);
    const [showInvoiceAmount, setShowInvoiceAmount] = useState(UserSettings.getUserSettings().showInvoiceAmount);
    const [showInvoiceNumber, setShowInvoiceNumber] = useState(UserSettings.getUserSettings().showInvoiceNumber);
    const [hasWrite, setHasWrite] = useState(false);
    const [campaignQueryData, setCampaignQueryData] = useState([]);
    const [campaignTableData, setCampaignTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [monthModal, setMonthModal] = useState({visible: false});
    const [transferModal, setTransferModal] = useState({visible: false});
    const sampleYear = 2024;
    const zeroBasedVisibleColumnIndexMonthOffset = 3;

    const columns = [
        {
            name: 'Company',
            selector: row => row.company.name,
            sortable: true
        },
        {
            name: 'Campaign',
            selector: row => row.campaign.name,
            sortable: true
        },
        {
            name: 'Platform',
            selector: row => getPlatformText(row.platform),
            sortable: true
        }
    ];
    for (let monthIndex = 0; monthIndex <= monthsInYear; monthIndex++) {
        let monthHeader = new Date(sampleYear, monthIndex).toLocaleString('default', { month: 'short'});
        columns.push(
            {
                name: monthHeader,
                cell: (row, index, column) => {
                    let campaignGroupMonth =  row.months.find( x=> monthIndex + 1 === x.month);
                    let columnSelector = '';
                    if (campaignGroupMonth) {
                        columnSelector = `${row.company.id}-${row.campaign.id}-${row.platform.id}-${sampleYear}-${campaignGroupMonth.month -1}`;
                    }
                    return (
                        <CampaignGroupCell
                            campaignGroupMonth={campaignGroupMonth}
                            showIo={showIo}
                            hasWrite={hasWrite}
                            showIoAmount={showIoAmount}
                            showPlannedAmount={showPlannedAmount}
                            showMargin={showMargin}
                            showInvoiceAmount={showInvoiceAmount}
                            showInvoiceNumber={showInvoiceNumber}
                            columnSelector={columnSelector}
                            setTransferModal={setTransferModal}
                            row={row}
                            selectedYear={selectedYear}
                            campaignQueryData={campaignQueryData}
                            showMonthModal={showMonthModal}
                            month={monthIndex+1}
                            showFlightDate={showFlightDate}
                        />
                    );
                },
                sortable: true,
                wrap: true,
                width: '200px'
            }
        );
    }
    columns.push(
    {
        name: 'Totals',
        cell: (row, index, column) => {
            let plannedTotal = CurrencyDefaults.factory(0);
            let invoicedTotal = CurrencyDefaults.factory(0);
            for (let month of row.months) {
                const visibleMonthIndexes = visibleColumnIndexes.filter(value => value >= zeroBasedVisibleColumnIndexMonthOffset && value <= monthsInYear + zeroBasedVisibleColumnIndexMonthOffset );
                let latestVisibleMonthColumnIndex = Math.max(...visibleMonthIndexes);
                if (isMonthVisible(month.month) &&
                    (month.rollover === 1 || latestVisibleMonthColumnIndex === (month.month - 1 + zeroBasedVisibleColumnIndexMonthOffset))
                ) {
                    plannedTotal = plannedTotal.add(month.plannedInvoiceAmount);
                    if (month.actualExpense && month.actualExpense.invoiceAmount) {
                        invoicedTotal = invoicedTotal.add(month.actualExpense.invoiceAmount);
                    }
                }
            }
            let balance = plannedTotal.subtract(invoicedTotal);
            return (<div className="campaign-group-cell">
                <div>Planned: {CurrencyDefaults.format(plannedTotal)}</div>
                <div>Invoiced: {CurrencyDefaults.format(invoicedTotal)}</div>
                <div>Balance: {CurrencyDefaults.format(balance)}</div>
            </div>);
        },
        sortable: true
    });

    for (let index = 0; index < columns.length; index++) {
        columns[index].omit = !visibleColumnIndexes.includes(index);
    }

    function showMonthModal(campaignGroupMonth, company, campaign, platform, month) {
        if (!hasWrite) {
            return;
        }
        let marginWarningText = '';
        let margin = 0;
        if (campaignGroupMonth && campaignGroupMonth.ioLineItems && campaignGroupMonth.ioLineItems.length > 0) {
            let hasSingleMargin = campaignGroupMonth.ioLineItems.every(obj => obj.margin === campaignGroupMonth.ioLineItems[0].margin);
            margin = campaignGroupMonth.ioLineItems[0].margin;
            if (!hasSingleMargin) {
                let margins = campaignGroupMonth.ioLineItems.map(x => x.margin);
                marginWarningText = `Multiple IO's exist with differing margins for this campaign month: ${margins}. Entering a new margin will update all margins for this campaign month.`;
                margin = null;
            }
        }
        setMonthModal({
            visible: true,
            company: company,
            campaign: campaign,
            platform: platform,
            year: selectedYear,
            month: month,
            campaignGroupMonth: campaignGroupMonth,
            inputInvoiceAmount: campaignGroupMonth && campaignGroupMonth.actualExpense ? campaignGroupMonth.actualExpense.invoiceAmount : null,
            inputInvoiceNumber: campaignGroupMonth && campaignGroupMonth.actualExpense ? campaignGroupMonth.actualExpense.invoiceNumber : null,
            originalMargin: margin,
            margin: margin,
            marginWarningText: marginWarningText
        });
    }
    
    /*
     * Returns column index for month starting at 1.
     */
    function getColumnIndexForMonth(monthIndexStartingAtZero) {
        return monthIndexStartingAtZero + zeroBasedVisibleColumnIndexMonthOffset;
    }

    function isMonthVisible(monthIndexStartingAtOne) {
        let monthIndexStartingAtZero = monthIndexStartingAtOne - 1;
        return visibleColumnIndexes.includes(monthIndexStartingAtZero + zeroBasedVisibleColumnIndexMonthOffset);
    }

    useEffect(() => {
        async function lookupUserRoles() {
            let token = await new ApiClient().getToken();
            let decodedToken = jwtDecode(token);
            setHasWrite(decodedToken.roles.includes('mxs.write'));
        }
        lookupUserRoles();
    }, []);

    useEffect(() => {
        setData(campaignQueryData);
    }, [companySelectValue, campaignSelectValue, platformSelectValue, invoiceNumberSearch]);

    useEffect(() => {
        async function fetchData() {
            try {
                setTableLoading(true);
                let data = await new ApiClient().fetch(`/Campaign/group/${selectedYear}`, 'GET', null, false);
                setCampaignQueryData(data);
                setTableLoading(false);
                clearSearch();
                setData(data);
            } catch (error) {
                toast.error(error, { autoClose: false});
            }
        }
        fetchData();
    }, [selectedYear]);
    
    useEffect(() => {
        async function fetchData() {
            try {
                let yearsResponse = await  new ApiClient().fetch('/Campaign/years', 'GET', null, false);
                let yearToBeSelected;
                if (yearsResponse.find(x => x === nowYear)) {
                    yearToBeSelected = nowYear;
                } else {
                    yearToBeSelected = yearsResponse[0];
                }
                setSelectedYear(yearToBeSelected);
                setYears(yearsResponse);
            } catch (error) {
                toast.error(error, { autoClose: false});
            }
        }
        fetchData();
    },[]);

    function setFilters(data) {
        const uniqueCompanies = Array.from(new Set(data.map(x => x.company.name)));
        uniqueCompanies.sort();
        setCompanySelectData(uniqueCompanies.map(x => ({ label: x, value: x })));
        const uniqueCampaigns = Array.from(new Set(data.map(x => x.campaign.name)));
        uniqueCampaigns.sort();
        setCampaignSelectData(uniqueCampaigns.map(x => ({ label: x, value: x })))
        const uniquePlatformIds = Array.from(new Set(data.map(x => x.platform.id)));
        const uniquePlatforms = Array.from(uniquePlatformIds.map(x => data.find(y => x === y.platform.id).platform));
        uniquePlatforms.sort((a, b) => (getPlatformText(a) > getPlatformText(b)) ? 1 : -1);
        setPlatformSelectData(uniquePlatforms.map(x => ({ label: getPlatformText(x), value: x.id })));
    }

    /*
     Applies filters and also databinds whatever is in campaignQueryData to the table.
     */
    function setData(data) {
        const filteredData = data.reduce((acc, campaignGroup) => {
            const invoiceFilter = invoiceNumberSearch && invoiceNumberSearch.length > 0;
            const companyMatch = !companySelectValue || campaignGroup.company.name === companySelectValue;
            const campaignMatch = !campaignSelectValue || campaignGroup.campaign.name === campaignSelectValue;
            const platformMatch = !platformSelectValue.length || getPlatformText(campaignGroup.platform) === platformSelectValue;
            const monthVisible = campaignGroup.months.some(month => isMonthVisible(month.month));
            const invoiceNumberMatch = !invoiceFilter || campaignGroup.months.some(month => {
                return month.actualExpense && month.actualExpense.invoiceNumber && month.actualExpense.invoiceNumber.toString().includes(invoiceNumberSearch);
            });
            if (companyMatch && campaignMatch && platformMatch && monthVisible && invoiceNumberMatch) {
                acc.push(campaignGroup);
            }
            return acc;
        }, []);
        setFilters(filteredData);
        setCampaignTableData(filteredData);
    }

    function clearSearch() {
        setCompanySelectValue('');
        setCampaignSelectValue('');
        setPlatformSelectValue('');
        setInvoiceNumberSearch('');
    }

    async function saveActualExpense() {

        let decimalPattern = /^-?\d+(\.\d+)?$/;
        if (!decimalPattern.test(monthModal.inputInvoiceAmount)) {
            setMonthModal({
                ...monthModal,
                errorMessage: "Invoiced must be a number eg. 1.01"
            });
            return;
        }
        
        if (monthModal.margin !== monthModal.originalMargin && monthModal.campaignGroupMonth) {
            let decimalPattern = /^\d*(\.\d+)?$/;
            if (monthModal.margin > 1 || !decimalPattern.test(monthModal.margin)) {
                setMonthModal({
                    ...monthModal,
                    errorMessage: "Margin must be a decimal value no greater than 1 e.g. .10"
                });
                return;
            }
            let plannedDataOut = {
                ioLineItemIds: monthModal.campaignGroupMonth.ioLineItems.map(x => x.id),
                margin: monthModal.margin
            };
            try {
                await new ApiClient().fetch('/Campaign/group/planned-expense', 'POST', plannedDataOut, {loadingMessage: 'Saving margin', successMessage: 'Saved margin'});
                let campaignGroupModified = campaignQueryData.find(x =>
                    x.company.id === monthModal.company.id &&
                    x.campaign.id === monthModal.campaign.id &&
                    x.platform.id === monthModal.platform.id)
                let campaignGroupMonth = campaignGroupModified.months.find(x => x.month === monthModal.month);
                let ioAmount = CurrencyDefaults.factory(campaignGroupMonth.ioAmount);
                let newMarginAmount = ioAmount.multiply(monthModal.margin);
                let plannedAmount = CurrencyDefaults.factory(campaignGroupMonth.ioAmount).subtract(newMarginAmount);
                campaignGroupMonth.plannedInvoiceAmount = CurrencyDefaults.trim(plannedAmount);
                for (let i = 0; i < campaignGroupMonth.ioLineItems.length; i++) {
                    campaignGroupMonth.ioLineItems[i] = {
                        ...campaignGroupMonth.ioLineItems[i],
                        margin: monthModal.margin
                    }
                }
            } catch (error) {
                // Toast's in api client handle messaging.
            }
        }
        
        try {
            let dataOut = {
                year: monthModal.year,
                month: monthModal.month,
                companyId: monthModal.company.id,
                campaignId: monthModal.campaign.id,
                platformId: monthModal.platform.id,
                invoiceAmount: monthModal.inputInvoiceAmount,
                invoiceNumber: monthModal.inputInvoiceNumber
            };
            let updated = await new ApiClient().fetch('/Campaign/group/actual-expense', 'POST', dataOut, {loadingMessage: 'Saving actual expense', successMessage: 'Saved actual expense'});
            let campaignGroupModified = campaignQueryData.find(x =>
                x.company.id === monthModal.company.id &&
                x.campaign.id === monthModal.campaign.id &&
                x.platform.id === monthModal.platform.id)
            let campaignGroupMonth = campaignGroupModified.months.find(x => x.month === monthModal.month);
            if (!campaignGroupMonth) {
                campaignGroupMonth = {
                    month: monthModal.month,
                    ioAmount: '0.0',
                    ioLineItems: [],
                    actualExpense: updated
                }
                campaignGroupModified.months.push(campaignGroupMonth);
            }
            campaignGroupMonth.actualExpense = updated;
            setData(campaignQueryData)
            setMonthModal({visible: false});
        } catch (error) {
            // Toast's in api client handle messaging.
        }
    }
    
    async function saveTransfer() {

        setTransferModal({
            ...transferModal,
            saving: true
        });
        
        let errorMessage = '';
        if (transferModal.receivingCampaign == null || transferModal.receivingCampaign.value < 1) {
            errorMessage = 'A campaign to transfer to must be selected.';
        }
        let monetaryPattern = /^(?:[1-9]\d*|0)(?:\.\d+)?$/;
        if (!monetaryPattern.test(transferModal.internalExpenseAmount)) {
            errorMessage = 'Planned must be a positive dollar amount e.g. 1000.00';
        }
        if (transferModal.ioAmount > transferModal.campaignGroupMonth.ioAmount) {
            errorMessage = 'Transfer amount can\'t exceed budget';
        }
        if (errorMessage) {
            setTransferModal({
                ...transferModal,
                errorMessage: errorMessage
            });
            return;
        }
        let postData = {
            sendingCampaignGroup: {
                year: transferModal.year,
                month: transferModal.month,
                companyId: transferModal.company.id,
                campaignId: transferModal.campaign.id,
                platformId: transferModal.platform.id
            },
            receivingCampaignGroup: {
                year: transferModal.receivingYear.value,
                month: transferModal.receivingMonth.value,
                companyId: transferModal.company.id,
                campaignId: transferModal.receivingCampaign.value,
                platformId: transferModal.platform.id
            },
            ioAmount: transferModal.ioAmount
        };

        try {
            await new ApiClient().fetch('/Campaign/group/transfer', 'POST', postData, {loadingMessage: 'Transferring budget', successMessage: 'Budget tranferred'});
        } catch (error) {
            // Toast's in api client handle messaging.
        } finally {
            let data = await new ApiClient().fetch(`/Campaign/group/${selectedYear}`, 'GET', null, false);
            setCampaignQueryData(data);
            setData(data);
        }
        
        setTransferModal({visible: false});
    }

    function getPlatformText(platform) {
        if (!platform) {
            return 'Any';
        }
        let platformName = (platform.description || '').toLowerCase() === (platform.name || '').toLowerCase()
            ? platform.description
            : `${platform.description} - ${platform.name}`;
        return platformName;
    }

    function toggleColumnVisibility(index) {
        let newVisibleColumnIndexes = visibleColumnIndexes.includes(index)
            ? visibleColumnIndexes.filter(indexValue => indexValue !== index)
            : [...visibleColumnIndexes, index];
        setVisibleColumnIndexes(newVisibleColumnIndexes);
        let userSettings = UserSettings.getUserSettings();
        userSettings.visibleColumnIndexes = newVisibleColumnIndexes;
        localStorage.setItem('userSettings', JSON.stringify(userSettings));
    }

    return <Template selectedNavItem="media-expenses">
            <div className="container-fluid media-expense-screen">
                <div className="row">
                </div>
                <div className="row">
                    <div className="col-xl-2">
                        <div className="form-floating">
                            <select
                                id="budget-year"
                                className="form-select"
                                value={selectedYear}
                                onChange={e => {
                                    setSelectedYear(parseInt(e.target.value));
                                }}
                            >
                                {years.map(year => <option key={year}>{year}</option>)}
                            </select>
                            <label htmlFor="budget-year">Budget Year</label>
                        </div>
                    </div>
                    <div className="col-xl-2">
                        <ReactSelect
                            className="react-select"
                            options={companySelectData}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Company"
                            onChange={(option) => setCompanySelectValue(option ? option.label : '')}
                        />
                    </div>
                    <div className="col-xl-2">
                        <ReactSelect
                            className="react-select"
                            options={campaignSelectData}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Campaign"
                            onChange={(option) => setCampaignSelectValue(option ? option.label : '')}
                        />
                    </div>
                    <div className="col-xl-2">
                        <ReactSelect
                            className="react-select"
                            options={platformSelectData}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Platform"
                            onChange={(option) => setPlatformSelectValue(option ? option.label : '')}
                        />
                    </div>
                    <div className="col-xl-2">
                        <div className="form-floating mb-3">
                            <input id="invoiceNumberFilter" placeholder="Invoice Number" className="form-control"
                                   value={invoiceNumberSearch}
                                   onChange={(e) => { setInvoiceNumberSearch(e.target.value) }}/>
                            <label htmlFor="invoiceNumberFilter">Invoice Number</label>
                        </div>
                    </div>
                    <div className="col-xl-2">
                        <button className={`btn btn-orange btn-header`}
                                onClick={() => {
                                    setEditingColumns(!editingColumns)
                                    setData(campaignQueryData);
                                }}
                        >Edit Columns</button>
                    </div>
                </div>
                {
                    editingColumns && <div className="edit-columns-container">
                        <hr />
                        <div className="row edit-columns-row">
                            <div className="col-2">
                                <div className="edit-column-header header">Columns & Fields</div>
                                <div className="custom-control custom-checkbox small">
                                    <input type="checkbox" className="custom-control-input" id="company-toggle-input"
                                           checked={visibleColumnIndexes.includes(0)}
                                           onChange={(e) => toggleColumnVisibility(0)}
                                    />
                                    <label className="custom-control-label" htmlFor={`company-toggle-input`}>Company</label>
                                </div>
                                <div className="custom-control custom-checkbox small">
                                    <input type="checkbox" className="custom-control-input" id="campaign-toggle-input"
                                           checked={visibleColumnIndexes.includes(1)}
                                           onChange={(e) => toggleColumnVisibility(1)}
                                    />
                                    <label className="custom-control-label" htmlFor={`campaign-toggle-input`}>Campaign</label>
                                </div>
                                <div className="custom-control custom-checkbox small">
                                    <input type="checkbox" className="custom-control-input" id="platform-toggle-input"
                                           checked={visibleColumnIndexes.includes(2)}
                                           onChange={(e) => toggleColumnVisibility(2)}
                                    />
                                    <label className="custom-control-label" htmlFor={`platform-toggle-input`}>Platform</label>
                                </div>
                                <div className="custom-control custom-checkbox small">
                                    <input type="checkbox" className="custom-control-input" id="totals-toggle-input"
                                           checked={visibleColumnIndexes.includes(15)}
                                           onChange={(e) => toggleColumnVisibility(15)}
                                    />
                                    <label className="custom-control-label" htmlFor={`totals-toggle-input`}>Totals</label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="edit-column-header header">Media Expense Details</div>
                                {
                                    <div className="custom-control custom-checkbox small">
                                        <input
                                            id="show-flight-date-toggle-input"
                                            className="custom-control-input"
                                            value={showFlightDate}
                                            checked={showFlightDate}
                                            onChange={e => {
                                                setShowFlightDate(!showFlightDate);
                                                let userSettings = UserSettings.getUserSettings();
                                                userSettings.showFlightDate = !showFlightDate;
                                                localStorage.setItem('userSettings', JSON.stringify(userSettings))
                                            }}
                                            type="checkbox" />
                                        <label className="custom-control-label" htmlFor={`show-flight-date-toggle-input`}>Flight Date</label>
                                    </div>
                                }
                                {
                                    hasWrite &&
                                    <div className="custom-control custom-checkbox small">
                                        <input
                                            id="io-toggle-input"
                                            className="custom-control-input"
                                            value={showIo}
                                            checked={showIo}
                                            onChange={e => {
                                                setShowIo(!showIo);
                                                let userSettings = UserSettings.getUserSettings();
                                                userSettings.showIo = !showIo;
                                                localStorage.setItem('userSettings', JSON.stringify(userSettings))
                                            }}
                                            type="checkbox" />
                                        <label className="custom-control-label" htmlFor={`io-toggle-input`}>IO</label>
                                    </div>
                                }
                                {
                                    hasWrite &&
                                    <div className="custom-control custom-checkbox small">
                                        <input
                                            id="io-amount-toggle-input"
                                            className="custom-control-input"
                                            value={showIoAmount}
                                            checked={showIoAmount}
                                            onChange={e => {
                                                setShowIoAmount(!showIoAmount);
                                                let userSettings = UserSettings.getUserSettings();
                                                userSettings.showIoAmount = !showIoAmount;
                                                localStorage.setItem('userSettings', JSON.stringify(userSettings))
                                            }}
                                            type="checkbox" />
                                        <label className="custom-control-label" htmlFor={`io-amount-toggle-input`}>Gross</label>
                                    </div>
                                }
                                <div className="custom-control custom-checkbox small">
                                    <input
                                        id="planned-toggle-input"
                                        className="custom-control-input"
                                        value={showPlannedAmount}
                                        checked={showPlannedAmount}
                                        onChange={e => {
                                            setShowPlannedAmount(!showPlannedAmount);
                                            let userSettings = UserSettings.getUserSettings();
                                            userSettings.showPlannedAmount = !showPlannedAmount;
                                            localStorage.setItem('userSettings', JSON.stringify(userSettings))
                                        }}
                                        type="checkbox" />
                                    <label className="custom-control-label" htmlFor={`planned-toggle-input`}>Planned</label>
                                </div>
                                {
                                    hasWrite &&
                                    <div className="custom-control custom-checkbox small">
                                        <input
                                            id="margin-toggle-input"
                                            className="custom-control-input"
                                            value={showMargin}
                                            checked={showMargin}
                                            onChange={e => {
                                                setShowMargin(!showMargin);
                                                let userSettings = UserSettings.getUserSettings();
                                                userSettings.showMargin = !showMargin;
                                                localStorage.setItem('userSettings', JSON.stringify(userSettings))
                                            }}
                                            type="checkbox" />
                                        <label className="custom-control-label" htmlFor={`margin-toggle-input`}>Margin</label>
                                    </div>
                                }
                                <div className="custom-control custom-checkbox small">
                                    <input
                                        id="invoiced-toggle-input"
                                        className="custom-control-input"
                                        value={showInvoiceAmount}
                                        checked={showInvoiceAmount}
                                        onChange={e => {
                                            setShowInvoiceAmount(!showInvoiceAmount);
                                            let userSettings = UserSettings.getUserSettings();
                                            userSettings.showInvoiceAmount = !showInvoiceAmount;
                                            localStorage.setItem('userSettings', JSON.stringify(userSettings))
                                        }}
                                        type="checkbox" />
                                    <label className="custom-control-label" htmlFor={`invoiced-toggle-input`}>Invoiced</label>
                                </div>
                                <div className="custom-control custom-checkbox small">
                                    <input
                                        id="invoice-number-toggle-input"
                                        className="custom-control-input"
                                        value={showInvoiceNumber}
                                        checked={showInvoiceNumber}
                                        onChange={e => {
                                            setShowInvoiceNumber(!showInvoiceNumber);
                                            let userSettings = UserSettings.getUserSettings();
                                            userSettings.showInvoiceNumber = !showInvoiceNumber;
                                            localStorage.setItem('userSettings', JSON.stringify(userSettings))
                                        }}
                                        type="checkbox" />
                                    <label className="custom-control-label" htmlFor={`invoice-number-toggle-input`}>Invoice Number</label>
                                </div>
                            </div>
                            <div className="col-3 edit-column-months">
                                <div className="edit-column-header header">Months</div>
                                <div className="container">
                                    <div className="row">
                                        {
                                            Config.monthNames.map((monthName, index) => {
                                                return (
                                                    <div className="col-md-4 custom-control custom-checkbox small" key={`month-toggle-container-${monthName}`}>
                                                        <input type="checkbox" className="custom-control-input" id={`month-toggle-input-${monthName}`}
                                                            checked={visibleColumnIndexes.includes(getColumnIndexForMonth(index))}
                                                            onChange={(e) => toggleColumnVisibility(getColumnIndexForMonth(index))}
                                                        />
                                                        <label className="custom-control-label" htmlFor={`month-toggle-input-${monthName}`}>{monthName}</label>
                                                    </div>)
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-xl-12">
                        <DataTable
                            columns={columns}
                            data={campaignTableData}
                            striped={true}
                            highlightOnHover={true}
                            dense={true}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[20, 50, 100]}
                            paginationComponentOptions={{
                                selectAllRowsItem: true
                            }}
                            progressPending={tableLoading}
                            responsive={true}
                        />
                    </div>
                </div>
            </div>
        {
            <ActualExpenseModal
                model={monthModal}
                updateModel={(updated) => setMonthModal(updated)}
                cancel={() => setMonthModal({visible: false})}
                save={saveActualExpense}
            />
        }
        {
            <MoveExpenseModal
                model={transferModal}
                updateModel={(updated) => setTransferModal(updated)}
                cancel={() => setTransferModal({visible: false})}
                save={saveTransfer}
            />
        }
    </Template>;
}
export default MediaExpenses;
